// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "D_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "D_fR d_fR d_bz d_bJ";
export var menuDesign6 = "D_vy d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "D_vz d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "D_vB d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "D_vC d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "D_vD d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "D_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "D_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "D_vF";
export var navbarItem = "D_nb d_bx";
export var navbarLogoItemWrapper = "D_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "D_vG d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "D_vH d_gd d_by d_Z d_bs";
export var burgerToggle = "D_vJ d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "D_vK d_gd d_by d_Z d_bs";
export var burgerInput = "D_vL d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "D_vM d_f3 d_w d_H";
export var burgerLine = "D_vN d_f1";
export var burgerMenuDesign6 = "D_vP d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "D_vQ d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "D_vR d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "D_vS d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "D_vT d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "D_vV d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "D_vW d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "D_vX d_bC d_bP";
export var compact = "D_vY";
export var navDivided = "D_vZ";
export var staticBurger = "D_v0";
export var menu = "D_v1";
export var navbarDividedLogo = "D_v2";
export var nav = "D_v3";