// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "y_sX d_gv d_cs";
export var heroHeaderCenter = "y_gw d_gw d_cs d_dw";
export var heroHeaderRight = "y_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "y_sY d_gr d_cw";
export var heroParagraphCenter = "y_gs d_gs d_cw d_dw";
export var heroParagraphRight = "y_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "y_sZ d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "y_s0 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "y_s1 d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "y_s2 d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "y_s3 d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "y_s4 C_s4";
export var heroExceptionNormal = "y_s5 C_s5";
export var heroExceptionLarge = "y_s6 C_s6";
export var Title1Small = "y_s7 C_s7 C_tq C_tr";
export var Title1Normal = "y_s8 C_s8 C_tq C_ts";
export var Title1Large = "y_s9 C_s9 C_tq C_tt";
export var BodySmall = "y_tb C_tb C_tq C_tK";
export var BodyNormal = "y_tc C_tc C_tq C_tL";
export var BodyLarge = "y_td C_td C_tq C_tM";